import { Typography } from '@mui/material';
import React from 'react';
import '../widgets.scss';
import { api } from '../../../utils/Api';
import { useQuery } from '@tanstack/react-query';
import { useNavigationCtx } from '../../NavigationProvider';
import { DashboardContext } from '../../../context/DashboardContext.jsx';
import { WidgetCard } from '../WidgetCard.jsx';
export default function BuildingInfo() {
  const { refreshEvent } = React.useContext(DashboardContext);
  const navigationCtx = useNavigationCtx();
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const widgetType = 'building';
  const query = useQuery({
    queryKey: ['buildingInfo', projectId, buildingId, refreshEvent],
    enabled: !!projectId && !!buildingId,
    queryFn:
      /**
       * @typedef {{
       *         widgetType: string;
       *         buildingId: string;
       *         buildingName: string;
       *         projectId: string;
       *         address: string;
       *         contact?: {
       *             name: string;
       *             email: string;
       *         };
       *          geoCoordinates?: {
       *             latitude: string;
       *             longitude: string;
       *         };
       *         size: number;
       *     }} APIResponseData
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          // return await resolveAfterMs('eeerooor', 3000, false);
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           * */
          const response = await api.get(
            `navigation/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType
              }
            }
          );
          if (response.data.status === 'error')
            if (response.data.errorMessage)
              return {
                status: 'error',
                errorMessage:
                  response.data.errorMessage === 'No data for date range' ||
                  response.data.errorMessage === 'No data for record'
                    ? response.data.errorMessage
                    : 'unknown error'
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'"
              );
          if (!response.data.data)
            throw new Error("unable to find 'data' property in API response");
          return { status: 'success', data: response.data.data };
        } catch (e) {
          console.error(e || 'some error occured');
          return {
            status: 'error',
            errorMessage: 'unknown error'
          };
        }
      }
  });

  const numFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <WidgetCard
      // preview={
      //   // <img
      //   //   src="/edit-dashboard.png"
      //   //   width="304"
      //   //   style={{ marginBottom: '12px' }}
      //   // />
      //   <img
      //     src="/building-1.png"
      //     style={{
      //       border: '1px solid rgba(230, 234, 234, 1)',
      //       borderRadius: '4px',
      //       width: '100%',
      //       objectFit: 'cover',
      //       marginBottom: '1rem'
      //     }}
      //   />
      // }
      scrollContainer={
        query.data?.status === 'success' ? (
          <>
            <WidgetLabelValuePair
              label={'Address'}
              value={query.data?.data.address || 'N/A'}
            />

            <WidgetLabelValuePair
              label={'Size'}
              value={
                query.data?.data.size
                  ? `${numFormatter.format(query.data?.data.size)} ft²`
                  : 'N/A'
              }
            />
            <WidgetLabelValuePair
              label={'Contact'}
              value={
                <>
                  {query.data?.data.contact?.name || 'N/A'}
                  <br />
                  {query.data?.data.contact?.email}
                </>
              }
            />
          </>
        ) : null
      }
      useQueryResult={query}
      headerValueComponent={
        query.data?.status === 'success' ? (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#1A1A1A',
              letterSpacing: 0
            }}
          >
            {query.data.data.buildingName}
          </Typography>
        ) : null
      }
      valueChangeComponent={<></>}
    />
  );
}

/**
 * @param {{
 *  label: string;
 *  value: import("react").ReactNode;
 * }} props
 */
function WidgetLabelValuePair(props) {
  return (
    <div className="list-row">
      <div className="label">
        <span>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              color: '#7C7C7C',
              letterSpacing: 0
            }}
          >
            {props.label}
          </Typography>
        </span>
      </div>
      <div className="value">
        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#1A1A1A',
            letterSpacing: 0
          }}
        >
          {props.value}
        </Typography>
      </div>
    </div>
  );
}
export function OldBuildingInfo() {
  return (
    <div className="widget">
      <div className="scroll-container">
        <img src="/map-img.png" width="100%" style={{ marginBottom: '12px' }} />

        <div className="list-row">
          <div className="label">
            <span>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#7C7C7C',
                  letterSpacing: 0
                }}
              >
                Building
              </Typography>
            </span>
          </div>
          <div className="value">
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#1A1A1A',
                letterSpacing: 0
              }}
            >
              Richardson Office
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
