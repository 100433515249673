import './AppLayout.scss';

import { useEffect, useState, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import SideBar from './SideBar.jsx';
import SubAppContainer from './SubAppContainer';
import TopAppBar from './TopAppBar';
import Box from '@mui/material/Box';
import { SidebarContext } from '../../context/SidebarContext.jsx';

import {
  urlPathToMicroApp
} from '../../MicroApps.js';
import HTMLHead from './HTMLHead';

import NoInternet from '../noInternet/NoInternet.jsx';
import { Providers } from './Providers.jsx';

let getSelectedAppTitle = (path) => {
  return (
    {
      '/': 'Home',
      '/ui/admin': 'Administration',
      '/ui/issue': 'Issues App'
    }[path] || ''
  );
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '56px !important'
}));

const drawerWidth = 240;

export const AppLayout = () => {
  const location = useLocation();
  let pathname = location.pathname;

  const { isOpen: open } = useContext(SidebarContext);
  // let localIsOpen = localStorage.getItem("navdrawerstateopen", "true") === "true"

  const [collapsed, setCollapsed] = useState(!open);
  const [useSubApp, setUseSubApp] = useState(false);
  const [IsLogin, setIsLogin] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  useEffect(() => {
    let microApp = urlPathToMicroApp(pathname);
    if (microApp) {
      setUseSubApp(true);
    }
  }, [pathname]);
  useEffect(() => {
    window.addEventListener('offline', () =>
      setNetwork(window.navigator.onLine)
    );

    window.addEventListener('online', () =>
      //setNetwork(window.navigator.onLine)
      window.location.reload(true)
    );
  });
  const drawerCallback = (drawerStatus) => {
    setCollapsed(drawerStatus);
  };

  return (
    <Providers>
      <HTMLHead />
      <div className="main-container">
        <TopAppBar title="CBRE SmartFM" navigateTo="/" />
        {isOnline ? (
          <div id="layout-container">
            <SideBar
              parentCallback={drawerCallback}
              collapsed={collapsed}
              pathname={pathname}
            />

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 0
                // marginLeft: !collapsed ? "240px" : "65px",
              }}
            >
              <DrawerHeader />
              <Outlet />

              <SubAppContainer useSubApp={useSubApp} />
            </Box>
          </div>
        ) : (
          <NoInternet />
        )}
      </div>
    </Providers>
  );
};
