import "./UserAvatar.scss";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import OktaAuth from "../../utils/okta.js";
import GlobalState from "../../store.js";
import Api from '../../utils/Api.js';

import { getUserData } from "../../utils/okta.js";

import UserProfileDropdown from "./UserProfileDropdown.jsx";

let getInitials = (fname, lname) => {
  let first = fname ? fname[0] : "";
  let last = lname ? lname[0] : "";
  return `${first}${last}`;
};

export default () => {

  let [hasProfilePic] = useState(false);
  let [projectList, setProjectList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dropdownAnchor = useRef();
  const dropdownMenuRef = useRef();
  let user = getUserData();

  const getApplicationList = async () => {
    try {
      //   setisLoading(true);
      let oktaToken = JSON.parse(localStorage.getItem("okta-token-storage"));

      let response = await Api.get("/getProjectsByUser/" + user.aud);



      if (response !== "") {
        let globalState = GlobalState.getGlobalState();
        let responseList = response.data;
        let idx = responseList.findIndex(
          (subArray) => subArray.isSelectedProject === true
        );
        let isListed = responseList
          .map((subArray) => subArray.project_name)
          .includes(globalState.project);

        if (!isListed) {
          globalState.project = response.data[idx].project_name;
          GlobalState.setGlobalState(globalState);
        }

        setProjectList(responseList);
      } else {
        setProjectList([]);
      }
    } catch (error) {
      setProjectList([]);
    }
  };

  useEffect(() => {
    getApplicationList();
  }, []);

  let navigate = useNavigate();

  let logoutHandler = () => {
    OktaAuth.logout();
    navigate("/logout/oidc/callback");
  };

  const toggleOpen = () => {
    setIsActive(!isActive);
  };

  const showDropdown = (event) => {
    setDropdownOpen(true);
  };

  const hideDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (isActive) {
      showDropdown();
    } else {
      hideDropdown();
    }

    function handleClickOutside(event) {
      if (
        dropdownMenuRef.current &&
        dropdownMenuRef.current !== null &&
        !event.target.classList.contains("MuiAutocomplete-option") &&
        !dropdownAnchor.current.contains(event.target) &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  let initials = getInitials(user.firstname, user.lastname);
  const id = dropdownOpen ? "user-profile-popover" : undefined;

  let avatarCircleClass = dropdownOpen
    ? "user-profile-avatar-circle-shadow"
    : "user-profile-avatar-circle";

  return (
    <>
      <div
        className="user-profile-avatar"
        onClick={() => toggleOpen()}
        aria-describedby={id}
        ref={dropdownAnchor}
      >
        <div className={avatarCircleClass}>
          <span className="user-profile-avatar-initials">{initials}</span>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <UserProfileDropdown
          ref={dropdownMenuRef}
          id={id}
          open={dropdownOpen}
          anchorEl={dropdownAnchor.current}
          hideDropdown={hideDropdown}
        />
      </div>
    </>
  );
};
