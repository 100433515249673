import './index.scss';

import React from 'react';
import ReactDOMClient from 'react-dom/client';
import App from './App.jsx';

let main = async () => {
  const root = ReactDOMClient.createRoot(
    document.getElementById('container-root')
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

main();
