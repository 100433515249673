import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  addGlobalUncaughtErrorHandler,
  registerMicroApps,
  start
} from 'qiankun';
import { createRoot } from 'react-dom/client';
import { CircularProgress } from '@mui/material';
import {
  AlertTriangle,
  Clock,
  Crosshair,
  DollarSign,
  Bell,
  FileText,
  Home,
  Smile,
  TrendingUp,
  Truck,
  User,
  Users,
  Zap
} from 'react-feather';

import { config } from './config/client/config.js';

const DEBUG = !process.env.NX_ENV || process.env.NX_ENV.includes('local');
let application_allowed = '';
const icons = {
  AlertTriangle: AlertTriangle,
  Clock: Clock,
  Crosshair: Crosshair,
  DollarSign: DollarSign,
  Bell: Bell,
  FileText: FileText,
  Home: Home,
  Smile: Smile,
  TrendingUp: TrendingUp,
  Truck: Truck,
  User: User,
  Users: Users,
  Zap: Zap
};

const microApps = [
  {
    category: 'Admin',
    key: 'adminUI',
    name: 'admin-ui',
    navPath: 'ui/admin',
    entry: config.adminUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/admin'),
    loader: (loading) => {}
  },
  {
    category: 'Application',
    key: 'issueUI',
    name: 'issue-analytics-ui',
    navPath: 'ui/issue',
    entry: config.issueUIUrl,
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/issue'),
    useHMR: true
  },
  {
    category: 'Application',
    key: 'maintenanceUI',
    name: 'maintenance-ui',
    navPath: 'ui/maintenance',
    entry: config.maintenanceUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/maintenance')
  },
  {
    category: 'Application',
    key: 'assetsUI',
    name: 'assets-ui',
    navPath: 'ui/cbm',
    entry: config.assetsUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/cbm')
  },
  {
    category: 'Application',
    key: 'smartCleaningUI',
    name: 'smart-cleaning-ui',
    navPath: 'ui/smart-cleaning',
    entry: config.smartCleaningUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/smart-cleaning')
  },
  {
    category: 'Application',
    key: 'legionellaMonitoringUI',
    name: 'legionella-monitoring-ui',
    navPath: 'ui/pipe-monitoring',
    entry: config.legionellaMonitoringUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/pipe-monitoring')
  },
  {
    category: 'Building Details',
    key: 'Alarms',
    name: 'Alarms',
    navPath: 'ui/reflow/alarms',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/alarms')
  },
  {
    category: 'Building Details',
    key: 'Equipment',
    name: 'Equipment',
    navPath: 'ui/reflow/equipments',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/reflow/equipments')
  },
  {
    category: 'Building Details',
    key: 'Schedules',
    name: 'Schedules',
    navPath: 'ui/reflow/schedules',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/schedules')
  },
  {
    category: 'Building Details',
    key: 'Histories',
    name: 'Histories',
    navPath: 'ui/reflow/histories',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/histories')
  },
  {
    category: 'Building Details',
    key: 'FloorPlans',
    name: 'Floor plans',
    navPath: 'ui/reflow/buildings',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/buildings')
  },
  {
    category: 'Building Details',
    key: 'Energy',
    name: 'Energy',
    navPath: 'ui/reflow/energy',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/energy')
  },
  {
    category: 'Building Details',
    key: 'Metering',
    name: 'Metering',
    navPath: 'ui/reflow/metering',
    entry: config.reflowUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) => location.pathname.includes('/ui/reflow/metering')
  },
  {
    category: 'Vantage Reports',
    key: 'AssetMgt',
    name: 'Asset Mgt',
    navPath: 'ui/vantage-dashboard/assetMgt',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/assetMgt')
  },
  {
    category: 'Vantage Reports',
    key: 'BuildingHealth',
    name: 'Building Health',
    navPath: 'ui/vantage-dashboard/BldHealth',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/BldHealth')
  },
  {
    category: 'Vantage Reports',
    key: 'EnS',
    name: 'E&S',
    navPath: 'ui/vantage-dashboard/ens',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/ens')
  },
  {
    category: 'Vantage Reports',
    key: 'FM',
    name: 'FM',
    navPath: 'ui/vantage-dashboard/fm',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/fm')
  },
  {
    category: 'Vantage Reports',
    key: 'IntegratedPortfolio',
    name: 'Integrated Portfolio',
    navPath: 'ui/vantage-dashboard/intPortfolio',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/intPortfolio')
  },
  {
    category: 'Vantage Reports',
    key: 'KPIs',
    name: 'KPIs',
    navPath: 'ui/vantage-dashboard/kpis',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/kpis')
  },
  {
    category: 'Vantage Reports',
    key: 'OM',
    name: 'OM',
    navPath: 'ui/vantage-dashboard/om',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/om')
  },
  {
    category: 'Vantage Reports',
    key: 'PJM',
    name: 'PJM',
    navPath: 'ui/vantage-dashboard/pjm',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/pjm')
  },
  {
    category: 'Vantage Reports',
    key: 'smartFM',
    name: 'smartFM',
    navPath: 'ui/vantage-dashboard/smartfm',
    entry: config.vantageUIUrl + '/',
    container: '#subapp-container',
    activeRule: (location) =>
      location.pathname.includes('/ui/vantage-dashboard/smartfm')
  }
];

let navigation = {
  adminUI: {},
  assetsUI: {},
  issueUI: {},
  maintenanceUI: {},
  smartCleaningUI: {},
  Alarms: {},
  Equipment: {},
  Schedules: {},
  Histories: {},
  FloorPlans: {},
  Energy: {},
  Metering: {},
  AssetMgt: {},
  BuildingHealth: {},
  EnS: {},
  FM: {},
  IntegratedPortfolio: {},
  KPIs: {},
  OM: {},
  PJM: {},
  smartFM: {}
};

const isPathToMicroApp = (pathname) => {
  let _path = pathname.replace(/\//, '').trim(); // replace leading slash
  let navPaths = microApps.map((m) => m.navPath);
  return navPaths.some((val) => _path.startsWith(val));
};

const urlPathToMicroApp = (path) => {
  return microApps.filter((m) => `/${m.navPath}` == path)[0];
};

const loadMicroAppNavigation = async () => {
  for (let m of microApps) {
    try {
      let resp = await axios.get(`${m.entry}navigation.json`);
      navigation[m.key] = resp.data;

      navigation[m.key].map((c) => {
        let path = `${m.navPath}${c.to}`;
        let LinkLabel = <Link to={path}>{c.label}</Link>;
        let Elem = icons[c.icon];
        c.icon = <Elem />;
        c.label = LinkLabel;
        return c;
      });
    } catch {
      console.error(`Failed to load navigation: ${m.entry}`);
    }
  }

  return navigation;
};

const loadAllMicroApps = async () => {
  // Qiankun Global Error Handling
  if (DEBUG) {
    addGlobalUncaughtErrorHandler((event) => {
      console.error(`Qiankun Global Error: `);
      console.error(event);
    });
  }

  try {
    await registerMicroApps(microApps, {
      beforeLoad: (app) => {},
      beforeMount: (app) => {},
      afterMount: () => {},
      beforeUnmount: () => {},
      afterUnmount: () => {},
      sandbox: true,
      experimentalStyleIsolation: true
    });
    setTimeout(function () {
      start({
        getPublicPath: (entry) => {
          const subapp = document.getElementById('subapp-container');
          const qiankunSubappNode = document.querySelector(
            '[id^="__qiankun_microapp_wrapper_for"]'
          );
          if (subapp && !qiankunSubappNode) {
            const subappRoot = createRoot(subapp);
            subappRoot.render(
              <div className="home-page-container ">
                <div className="home-page-loader">
                  <CircularProgress sx={{ color: '#003F2D' }} />
                </div>
              </div>
            );
          }
          return entry;
        }
      });
    }, 2000);
  } catch (e) {
    console.error('Error Starting MicroApp: ');
    console.error(e.message);
    console.error(e.stack);
  }
};

export {
  isPathToMicroApp,
  loadMicroAppNavigation,
  loadAllMicroApps,
  microApps,
  navigation,
  urlPathToMicroApp
};
