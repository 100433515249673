import { useEffect, useState } from 'react';
import {
  Stack
} from '@mui/material';
// import {
//   getDrawerOutletData,
//   setDrawerPriorityOutlet
// } from '@/services/getDrawerOutletData/index';
// import { DrawerPriorityAccordion } from '../DrawerPriorityAccordion/DrawerPriorityAccordion';
// import { SnackbarContext } from '@/components/decoupled-components/SnackbarContext';
// import { useDrawerStore } from '@/zustand/zustand.stores';
// import { useNavListStore } from './useNavListStore';
import { useSearchParams } from 'react-router-dom';
import { useDrawerContext } from './DrawerProvider';
import { useSnackbarContext } from './SnackbarContext';
import Survey from './Survey';

export default function NewDrawerPriority() {
  const { setDrawerComponent } = useDrawerContext();
  useEffect(() => {
    // Dynamically load the external JavaScript file
    const script = document.createElement('script');
    script.src = './surveyScript.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const suggestion = 'Sample suggestion';
  const [feedback, setFeedback] = useState('');
  //   const refetch = useNavListStore((s) => s.refetch);
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');

  const snackbar = useSnackbarContext();
  // const handleApply = () => {
  //   setDrawerComponent(null);

  //   document.getElementById('sg_navchoice').value = 'sGizmoSubmitButton';

  //   snackbar.setSnack({
  //     title: 'Thank you',
  //     message: 'Your feedback has been submitted.',
  //     autoHideDuration: 5000,
  //     severity: 'success',
  //     open: true,
  //     anchorOrigin: {
  //       horizontal: 'right',
  //       vertical: 'top'
  //     },
  //     onClose: () => {}
  //   });
  //   window.location.href =
  //     'https://survey.alchemer.com/s3/7674521/SmartFM-Provide-feedback';
  // };
  return (
    <Stack
      sx={{
        width: 480,
        padding: '20px',
        height: '100%'
      }}
      role="presentation"
    >
      <Survey />
    </Stack>

    // <Stack
    //   sx={{
    //     width: 480,
    //     padding: '20px',
    //     height: '100%'
    //   }}
    //   role="presentation"
    // >

    //   <div
    //     style={{
    //       height: '100%',
    //       overflow: 'auto',
    //       flexGrow: 1
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row',
    //         alignContent: 'center',
    //         justifyContent: 'space-between',
    //         alignItems: 'center'
    //       }}
    //     >
    //       <Typography
    //         id="drawer-heading"
    //         style={{
    //           fontFamily: 'Calibre-R',
    //           fontSize: '20px',
    //           fontWeight: '500px',
    //           lineHeight: '24px',
    //           letterSpacing: '-0.015em',
    //           textAlign: 'left',
    //           color: '#000000'
    //         }}
    //       >
    //         Feedback
    //       </Typography>
    //       <IconButton onClick={() => setDrawerComponent(null)}>
    //         <Close />
    //       </IconButton>
    //     </div>

    //     {/* <DrawerPriorityAccordion
    //       buildingName={
    //         outletData.data != undefined
    //           ? outletData?.data[0].site.name + ' ' + '(' + outletCount + ')'
    //           : 'N/A'
    //       }
    //       outlets={outlets}
    //     /> */}
    //     <Typography
    //       id="drawer-sub-heading"
    //       style={{
    //         fontFamily: 'Calibre-R',
    //         fontSize: '16px',
    //         fontWeight: '400px',
    //         lineHeight: '24px',
    //         letterSpacing: '0px',
    //         textAlign: 'left',
    //         color: '#1A1A1A',
    //         marginBottom: '20px'
    //       }}
    //     >
    //       Provide feedback about the SmartFM Solutions application. You can let
    //       our team know about bugs, feature ideas and general input.
    //     </Typography>
    //     <form
    //       action="https://survey.alchemer.com/s3/7674521/SmartFM-Provide-feedback"
    //       method="post"
    //       enctype="multipart/form-data"
    //       class="sg-survey-form"
    //       id="sg_FormFor7674521"
    //       novalidate
    //       target="_blank"
    //     >
    //       {/* <InputLabel id="demo-simple-select-label">
    //         How can we improve?
    //       </InputLabel> */}
    //       {/* <TextField
    //         // autosize={true}
    //         // onResize={(e) => e.target}
    //         multiline={true}
    //         rows={10}
    //         // labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={feedback}
    //         onChange={(e) => setFeedback(e.target.value)}
    //         // renderValue={(value) => value}
    //         label="How can we improve?"
    //         style={{
    //           maxHeight: '200px',
    //           overflow: 'auto'
    //         }}
    //         // onChange={(event) => {
    //         //   setSelectedOutlet(event.target.value);
    //         //   setSelectedAction(event.target.value);
    //         // }}
    //       /> */}
    //       <TextareaAutosize
    //         aria-label="minimum height"
    //         minRows={10}
    //         placeholder="How can we improve?"
    //         value={feedback}
    //         onChange={(e) => setFeedback(e.target.value)}
    //         style={{
    //           maxHeight: '100%',
    //           maxWidth: '100%',
    //           width: '95%',
    //           padding: '10px',
    //           borderRadius: '5px',
    //           border: '1px solid #D9D9D9',
    //           fontFamily: 'Calibre-R',
    //           fontSize: '16px',
    //           fontWeight: '400px',
    //           lineHeight: '24px',
    //           letterSpacing: '0px',
    //           textAlign: 'left',
    //           color: '#1A1A1A'
    //         }}
    //       />
    //     </form>
    //   </div>
    //   {/* <Stack
    //     sx={{
    //       flexDirection: 'row',
    //       alignItems: 'center',
    //       justifyContent: 'flex-end',
    //       gap: '4px'
    //     }}
    //   >
    //     <Button
    //       id="drawer-cancel-button"
    //       sx={{
    //         color: '#003F2D',
    //         textTransform: 'none'
    //       }}
    //       onClick={() => setDrawerComponent(null)}
    //     >
    //       Cancel
    //     </Button>
    //     <Button
    //       id="sg_SubmitButton"
    //       name="sGizmoSubmitButton"
    //       variant="contained"
    //       //   disabled={!selectedAction || selectedAction === ''}
    //       // onClick={handleApply}
    //       onClick={() => {
    //         document.getElementById('sg_navchoice').value =
    //           'sGizmoSubmitButton';
    //         // return true;
    //       }}
    //       sx={{
    //         color: 'White !important',
    //         backgroundColor: '#003F2D',
    //         '&:hover': { backgroundColor: '#003F2D !important' },
    //         textTransform: 'none'
    //       }}
    //     >
    //       Submit
    //     </Button>
    //     <div className="sg-hidden-inputs">
    //       <input type="hidden" name="sg_navchoice" id="sg_navchoice" value="" />
    //       <input
    //         type="hidden"
    //         name="sg_currentpageid"
    //         id="sg_currentpageid"
    //         value="1"
    //       />
    //       <input
    //         type="hidden"
    //         name="sg_surveyident"
    //         id="sg_surveyident"
    //         value="7674521"
    //       />
    //       <input type="hidden" name="sg_sessionid" id="sg_sessionid" value="" />
    //       <input
    //         type="hidden"
    //         name="sg_high_contrast"
    //         id="sg_high_contrast"
    //         value=""
    //       />
    //       <input
    //         type="hidden"
    //         name="sg_cint_panelist"
    //         id="sg_cint_panelist"
    //         value=""
    //       />
    //       <input
    //         id="sg_referer"
    //         type="hidden"
    //         name="sg_referer"
    //         value="https://app.alchemer.com/distribute/share/id/7674521"
    //       />
    //       <input
    //         type="hidden"
    //         name="sg_interactionlevel"
    //         id="sg_interactionlevel"
    //         value="0"
    //       />
    //       <input
    //         type="hidden"
    //         name="sg_interactionlevel_sig"
    //         id="sg_interactionlevel_sig"
    //         value="origin-0"
    //       />
    //       <input
    //         type="hidden"
    //         name="sg02db624dc9dd7c67c016e03865c3f4db"
    //         id="sg02db624dc9dd7c67c016e03865c3f4db"
    //         value=""
    //       />
    //     </div>
    //   </Stack> */}
    // </Stack>
  );
}
