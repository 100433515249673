import content from './config.smartfm.json' assert { type: 'json' };

// ${NX_ENV} special syntax
// we use a special envsub at `npm run start` to select the right env config
const nxEnv =
  !process.env.NX_ENV || process.env.NX_ENV === 'undefined'
    ? 'local-smartfm'
    : '${NX_ENV}'; // tokenized NX_ENV
const env = nxEnv.split('-')[0];
const config = { ...content.default, ...content[env] };

export { config };
