import { useEffect, useState } from 'react';
// import './surveyStyles.css'; // Import the CSS file
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useDrawerContext } from './DrawerProvider';
import { Close } from '@mui/icons-material';
import { useSnackbarContext } from './SnackbarContext';

const Survey = () => {
  const { setDrawerComponent } = useDrawerContext();
  const [feedback, setFeedback] = useState('');
  useEffect(() => {
    // Dynamically load the external JavaScript file
    const script = document.createElement('script');
    script.src = './surveyScript.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //   const handleApply = (e) => {
  //     console.log('Feedback submitted: ', e.target.value);
  //   };

  const snackbar = useSnackbarContext();

  // const feedbackMutRes = useMutation({
  //   mutationFn: async (feedback) => {
  //     const response = await fetch(
  //       'https://survey.alchemer.com/s3/7674521/SmartFM-Provide-feedback',
  //       {
  //         method: 'POST',
  //         body: JSON.stringify({ feedback }) // TODO chnage like form data
  //       }
  //     );
  //     const data = await response.text();
  //     return data;
  //   }
  // });

  const handleApply = () => {
    setDrawerComponent(null);

    // document.getElementById('sg_navchoice').value = 'sGizmoSubmitButton';

    snackbar.setSnack({
      title: 'Thank you',
      message: 'Your feedback has been submitted.',
      autoHideDuration: 5000,
      severity: 'success',
      open: true,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      onClose: () => {}
    });
    // window.location.href =
    //   'https://survey.alchemer.com/s3/7674521/SmartFM-Provide-feedback';
  };

  return (
    <div
      style={{
        flexGrow: '1',
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
      }}
      className="sg-wrapper"
    >
      <div className="sg-header">
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            flexGrow: 1
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              id="drawer-heading"
              style={{
                fontFamily: 'Calibre-R',
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '24px',
                letterSpacing: '-0.015em',
                textAlign: 'left',
                color: '#000000'
              }}
            >
              Feedback
            </Typography>
            <IconButton onClick={() => setDrawerComponent(null)}>
              <Close />
            </IconButton>
          </div>
        </div>

        <div className="sg-header-hook-2"></div>
      </div>
      <form
        style={{
          flexGrow: '1'
        }}
        action="https://survey.alchemer.com/s3/7674521/SmartFM-Provide-feedback"
        method="post"
        encType="multipart/form-data"
        className="sg-survey-form"
        id="sg_FormFor7674521"
        noValidate
        // target="_blank"
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          className="sg-content"
        >
          <div
            style={{
              flexGrow: '1'
            }}
            className="sg-question-set"
          >
            <div id="sgE-7674521-1-2-box" className="sg-question sg-type-essay">
              <div className="sg-question-title">
                <Typography
                  id="drawer-sub-heading"
                  style={{
                    fontFamily: 'Calibre-R',
                    fontSize: '16px',
                    fontWeight: '400px',
                    lineHeight: '24px',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#1A1A1A',
                    marginBottom: '20px'
                  }}
                >
                  Provide feedback about the SmartFM Solutions application. You
                  can let our team know about bugs, feature ideas and general
                  input.
                </Typography>
              </div>

              <TextField
                label="How can we improve?"
                aria-label="minimum height"
                rows={10}
                multiline={true}
                placeholder="How can we improve?"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                style={{
                  //   maxHeight: '100%',
                  //   maxWidth: '100%',
                  width: '100%',
                  //   padding: '10px',
                  //   borderRadius: '5px',
                  //   fontFamily: 'Calibre-R',
                  //   fontSize: '16px',
                  //   fontWeight: '400px',
                  //   lineHeight: '24px',
                  //   letterSpacing: '0px',
                  //   textAlign: 'left',
                  color: '#1A1A1A'
                }}
              />
            </div>
          </div>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '4px'
            }}
          >
            <div className="sg-content-hook-2"></div>
            <div className="sg-footer">
              <div className="sg-footer-hook-1"></div>
              <div className="sg-button-bar">
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '12px',
                    position: 'relative',
                    flexGrow: 1
                  }}
                >
                  <Button
                    id="drawer-cancel-button"
                    sx={{
                      color: '#003F2D',
                      textTransform: 'none'
                    }}
                    onClick={() => setDrawerComponent(null)}
                  >
                    Cancel
                  </Button>
                  <input
                    style={{
                      backgroundColor: '#003F2D',
                      color: '#FFFFFF',
                      minWidth: '64px',
                      textTransform: 'none',
                      padding: '10px',
                      borderRadius: '4px',
                      border: '1px solid #D9D9D9',
                      fontFamily: 'Calibre-R',
                      fontSize: '16px',
                      fontWeight: '500px',
                      letterSpacing: '0px'
                    }}
                    type="submit"
                    className="sg-button sg-submit-button"
                    id="sg_SubmitButton"
                    name="sGizmoSubmitButton"
                    aria-label="submit button"
                    // onClick={(event) => {
                    //   document.getElementById('sg_navchoice').value =
                    //     'sGizmoSubmitButton';
                    //   return true;
                    // }}
                    onClick={handleApply}
                    value="Submit"
                  />
                </Stack>
              </div>
              <div
                className="sg-progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow="0"
              >
                {/* <div className="sg-progress-bar-text">0%</div> */}
                <div className="sg-progress-bar-background">
                  <div
                    className="sg-progress-bar-inner"
                    style={{ width: '0%' }}
                  ></div>
                </div>
              </div>
              <div className="sg-footer-hook-2"></div>
            </div>
          </Stack>
        </div>
        <div className="sg-hidden-inputs">
          <input type="hidden" name="sg_navchoice" id="sg_navchoice" value="" />
          <input
            type="hidden"
            name="sg_currentpageid"
            id="sg_currentpageid"
            value="1"
          />
          <input
            type="hidden"
            name="sg_surveyident"
            id="sg_surveyident"
            value="7674521"
          />
          <input type="hidden" name="sg_sessionid" id="sg_sessionid" value="" />
          <input
            type="hidden"
            name="sg_high_contrast"
            id="sg_high_contrast"
            value=""
          />
          <input
            type="hidden"
            name="sg_cint_panelist"
            id="sg_cint_panelist"
            value=""
          />
          <input
            id="sg_referer"
            type="hidden"
            name="sg_referer"
            value="https://app.alchemer.com/distribute/share/id/7674521"
          />
          <input
            type="hidden"
            name="sg_interactionlevel"
            id="sg_interactionlevel"
            value="0"
          />
          <input
            type="hidden"
            name="sg_interactionlevel_sig"
            id="sg_interactionlevel_sig"
            value="origin-0"
          />
          <input
            type="hidden"
            name="sg02db624dc9dd7c67c016e03865c3f4db"
            id="sg02db624dc9dd7c67c016e03865c3f4db"
            value=""
          />
        </div>
      </form>
    </div>
  );
};

export default Survey;
