import { config } from '../config/client/config.js';
import { AnalyticsBrowser } from '@segment/analytics-next';

export async function identifyUserForAnalytics(email) {
  const { segmentIoKey, uuidGeneratorService } = config;
  const { baseUrl, params } = uuidGeneratorService;
  const analytics = AnalyticsBrowser.load({ writeKey: segmentIoKey });
  const queryParams = new URLSearchParams(params);
  queryParams.set('email', email);

  const queryString = queryParams.toString();
  const url = `${baseUrl}?${queryString}`;

  try {
    const uuid = await fetch(url).then((response) => response.text());
    // Pass UUID to Segment via identify method
    analytics.identify(uuid);
  } catch (error) {
    // do nothing
  }
}
