import { ThemeProvider } from '@mui/material/styles';
import { SelectTheme } from '../theme/SelectTheme.jsx';
import { DrawerProvider } from './DrawerProvider.jsx';
import { SnackbarProvider } from './SnackbarContext.jsx';

let theme = SelectTheme();

export function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <DrawerProvider>{children}</DrawerProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
