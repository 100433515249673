import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { DashboardContext } from '../../../context/DashboardContext.jsx';
import { WidgetCard, dateRangeId } from '../WidgetCard.jsx';
import { api } from './../../../utils/Api';
import { useNavigationCtx } from './../../NavigationProvider';
import { getWidgetFilterInterval } from '../../../utils/functions.js';
import { statusColor } from '../../../utils/constants.js';
import { firstCharUpperCase } from '@smartfm/react-components';

export default function AssetHealthWidget() {
  const title = 'Condition Based Maintenance';
  const widgetType = 'health';
  const { refreshDuration, refreshEvent } = React.useContext(DashboardContext);
  const [dateRange, setDateRange] = useState('This month');
  const navigationCtx = useNavigationCtx();
  const interval = dateRangeId(dateRange);
  const projectId = /** @type {string} */ (
    navigationCtx?.state.selectedOption?.project_nexus_id
  );
  const buildingId = /** @type {string} */ (
    navigationCtx?.state.selectedOption?.nexusId
  );
  const query = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn:
      /**
       * @typedef {Object} APIResponseData
       * @property {string} widgetType
       * @property {string} interval
       * @property {string} buildingId
       * @property {string} projectId
       * @property {string} startDate
       * @property {string} endDate
       * @property {{ field: string, value: string }[]} values
       * @property {null} deviation
       * @property {null} comparison
       * @property {null} arrow
       * @property {null} poorHealthPercent
       *
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       *
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           */
          const response = await api.get(
            `assets/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType,
                interval
              }
            }
          );
          if (response.data.status === 'error')
            if (response.data.errorMessage)
              return {
                status: 'error',
                errorMessage:
                  response.data.errorMessage === 'No data for date range' ||
                  response.data.errorMessage === 'No data for record'
                    ? response.data.errorMessage
                    : 'unknown error'
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'"
              );
          if (!response.data.data)
            throw new Error("unable to find 'data' property in API response");
          return { status: 'success', data: response.data.data };
        } catch (e) {
          console.error(e || 'some error occured');
          return {
            status: 'error',
            errorMessage: 'unknown error'
          };
        }
      }
  });
  const data = useMemo(
    () =>
      query.data && query.data.status === 'success'
        ? query.data.data.values.map((v) => ({
            name: v.field==='N/A' ? v.field 
              : `${firstCharUpperCase(v.field)} health`,
            value: v.value,
            color:
              v.field === 'good'
                ? statusColor.good
                : v.field === 'fair'
                ? statusColor.fair
                : v.field === 'poor'
                ? statusColor.poor
                : statusColor.not_applicable
          }))
        : [],
    [query.data]
  );
  const getLink = () => {
    if (query.data && query.data?.errorMessage) {
      return '';
    }
    return `/ui/cbm?recordId=${buildingId}&recordType=site&selectedTab=locations&intervalStart=${
      query.data?.status === 'success' ? query.data?.data.startDate : null
    }&intervalType=${getWidgetFilterInterval(interval)}`;
  };
  return (
    <WidgetCard
      scrollContainer={
        query.data?.status === 'success' ? (
          <div className="legend">
            <div className="legend-container">
              {data.map((entry, index) => (
                <div key={index} className="legend-row">
                  <div className="legend-row-label">
                    <span
                      className="legend-icon"
                      style={{ backgroundColor: entry.color }}
                    ></span>
                    <span className="legend-label">{entry.name}</span>
                  </div>
                  <span className="legend-value">{entry.value}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null
      }
      useQueryResult={query}
      title={title}
      link={getLink()}
      dateRange={dateRange}
      setDateRange={setDateRange}
      headerValueComponent={
        query.data?.status === 'success' ? (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#1A1A1A',
              letterSpacing: 0
            }}
          >{`${
            query.data.data.poorHealthPercent &&
            !isNaN(query.data.data.poorHealthPercent)
              ? query.data.data.poorHealthPercent
              : 0
          }% poor health`}</Typography>
        ) : null
      }
      valueChangeComponent={
        query.data?.status === 'success' ? (
          <>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 14 }}
            >
              {query.data.data.arrow
                ? query.data.data.arrow === 'up'
                  ? 'arrow_upward'
                  : 'arrow_downward'
                : null}
            </span>
            {query.data.data.comparison}
          </>
        ) : null
      }
    />
  );
}
