import { OktaAuth } from '@okta/okta-auth-js';

import GlobalState from '../store.js';

import { config } from '../config/client/config.js';
let oktaCfg = config.okta;

let authClient;
let AUTHENTICATED = false;

/* Function to trigger login */
const triggerLogin = () => {
  authClient.signInWithRedirect();
};

const getUserData = () => {
  let oktaJsonData = localStorage.getItem('okta-token-storage');
  if (!oktaJsonData) return {};

  let oktaData = JSON.parse(oktaJsonData);
  let userData = {
    email: oktaData.idToken?.claims.unique_name,
    firstname: oktaData.idToken?.claims.name.split(' ')[0],
    lastname: oktaData.idToken?.claims.name.split(' ')[1],
    aud: oktaData.idToken?.claims.unique_name
  };

  let globalState = GlobalState.getGlobalState();
  globalState.user = userData;
  GlobalState.setGlobalState(globalState);

  return userData;
};

let unsecuredRoutes = ['/logout/oidc/callback'];

export { getUserData };

export default {
  isAuthenticated: () => {
    return AUTHENTICATED;
  },
  logout: async () => {
    authClient.tokenManager.clear();
    authClient.signOut();
    authClient = null;
  },
  init: async (authEventNotify = () => null) => {
    const oktaConfig = {
      issuer: oktaCfg.issuer,
      clientId: oktaCfg.clientId,
      redirectUri: oktaCfg.redirectUri,
      tokenUrl: '/login/oidc/token',
      scopes: ['openid', 'profile', 'email'],
      responseType: 'code',
      pkce: true
    };

    // create OktaAuth instance
    authClient = new OktaAuth(oktaConfig);
    // Subscribe to authState change event.
    authClient.authStateManager.subscribe(function (authState) {
      if (unsecuredRoutes.includes(window.location.pathname)) {
        return;
      }

      authEventNotify(authState);
      // Logic based on authState is done here.
      if (!authState.isAuthenticated) {
        AUTHENTICATED = false;
        triggerLogin();
        return;
      } else {
        AUTHENTICATED = true;
      }

      // Render authenticated view
    });

    authClient.tokenManager.on('expired', function (key, expiredToken) {
      authClient.tokenManager.renew(key).then(function (newToken) {
        window.location.reload(true);
      });
    });

    // Handle callback
    if (authClient.token.isLoginRedirect()) {
      const { tokens } = await authClient.token.parseFromUrl();
      authClient.tokenManager.setTokens(tokens);
    }

    // will update auth state and call event listeners
    authClient.start();

    return authClient;
  }
};
