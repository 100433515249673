import { Stack, Typography, Skeleton } from '@mui/material';
import { ErrorCardContent } from './ErrorCardContent.jsx';
import TimePicker from './TimePicker/TimePicker.jsx';
import { timeDurationOptions } from '../../utils/constants.js';
// import { resolveAfterMs } from '@smartfm/react-components';

/**
 * @template {string} ErrorMessage
 * @template {unknown} Data
 * @param {{
 *  scrollContainer: import('react').ReactNode,
 *  useQueryResult:  import('@tanstack/react-query').UseQueryResult<{
 *          status: "success";
 *          data: Data;
 *      } | {
 *          status: "error";
 *          errorMessage: ErrorMessage | "unknown error";
 *      }, Error>
 *  title?: string;
 *  link?: string;
 *  dateRange?: string;
 *  setDateRange?: import('react').Dispatch<import('react').SetStateAction<string>>;
 *  noDataForTimeRange?: boolean;
 * headerValueComponent: import('react').ReactNode;
 *  valueChangeComponent: import('react').ReactNode;
 * icon?: import('react').ReactNode;
 * preview?: import('react').ReactNode;
 * timeDurationOptions?: {label:string, isDisabled?:boolean}[];
 * }} props
 */
export const WidgetCard = ({
  scrollContainer,
  useQueryResult,
  title,
  link,
  dateRange,
  setDateRange,
  noDataForTimeRange,
  headerValueComponent,
  valueChangeComponent,
  icon,
  preview,
  timeDurationOptions: _timeDurationOptions
}) => {
  return (
    <div className="widget">
      {useQueryResult.isFetching || !useQueryResult.isFetched ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <Skeleton variant="rectangular" height={'100%'} />
        </div>
      ) : useQueryResult.isError ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <ErrorCardContent onReload={() => useQueryResult.refetch()} />
        </div>
      ) : !useQueryResult.data ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <Typography
              variant="Calibre/Body 2"
              color={'rgba(26, 26, 26, 0.65)'}
            >
              {'No data is available'}
            </Typography>
          </div>
        </div>
      ) : useQueryResult.data.status === 'error' ? (
        useQueryResult.data.errorMessage === 'No data for record' ? (
          <div className="top" style={{ height: '100%' }}>
            <div className="top">
              <CardTitle title={title} icon={icon} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <Typography
                variant="Calibre/Body 2"
                color={'rgba(26, 26, 26, 0.65)'}
              >
                {'No data is available'}
              </Typography>
            </div>
          </div>
        ) : useQueryResult.data.errorMessage === 'No data for date range' ? (
          <WidgetContentForNoDataForTimeRange
            timeDurationOptions={_timeDurationOptions ?? timeDurationOptions}
            title={title}
            link={''}
            dateRange={dateRange}
            setDateRange={setDateRange}
            icon={icon}
          />
        ) : useQueryResult.data.errorMessage == 'Building not found' ? (
          <div className="top" style={{ height: '100%' }}>
            <div className="top">
              <CardTitle title={title} icon={icon} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <Typography
                variant="Calibre/Body 2"
                color={'rgba(26, 26, 26, 0.65)'}
              >
                {'No data is available for this building'}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="top" style={{ height: '100%' }}>
            <div className="top">
              <CardTitle title={title} link={link} icon={icon} />
            </div>
            <ErrorCardContent onReload={() => useQueryResult.refetch()} />
          </div>
        )
      ) : noDataForTimeRange ? (
        <WidgetContentForNoDataForTimeRange
          timeDurationOptions={_timeDurationOptions ?? timeDurationOptions}
          title={title}
          link={link}
          dateRange={dateRange}
          setDateRange={setDateRange}
          icon={icon}
        />
      ) : (
        <div className="top" style={{ height: '100%' }}>
          {preview}
          <div className="top">
            <CardTitle title={title} link={link} icon={icon} />
            <div className="value">
              <div className="val" style={{ width: '100%' }}>
                {headerValueComponent}
              </div>
            </div>
            <div className="time-range">
              {dateRange && setDateRange ? (
                <TimePicker
                  defaultVal={dateRange}
                  onChangeDateRange={setDateRange}
                  timeDurationOptions={
                    _timeDurationOptions ?? timeDurationOptions
                  }
                />
              ) : (
                <Typography variant="subtitle2">This week</Typography>
              )}
              {valueChangeComponent ? (
                <div className="value-change">{valueChangeComponent}</div>
              ) : null}
            </div>
          </div>
          {scrollContainer}
        </div>
      )}
    </div>
  );
};
export const CardTitle = ({ title, link = '', icon }) =>
  title || icon ? (
    <div className="label">
      <span>
        {link && link !== '' ? (
          <a href={link}>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                // color: '#1A1A1A',
                letterSpacing: 0
              }}
            >
              {title}
            </Typography>
          </a>
        ) : (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: 0
            }}
          >
            {title}
          </Typography>
        )}
      </span>
      {icon}
    </div>
  ) : null;
export const WidgetContentForNoDataForTimeRange = ({
  title,
  link,
  dateRange,
  setDateRange,
  icon,
  timeDurationOptions
}) => (
  <div className="top" style={{ height: '100%' }}>
    <div className="top">
      <CardTitle title={title} link={link} icon={icon} />
    </div>
    <Stack
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        gap: '4px'
      }}
    >
      No data is available for this time range
      <TimePicker
        defaultVal={dateRange}
        onChangeDateRange={setDateRange}
        timeDurationOptions={timeDurationOptions}
      />
    </Stack>
  </div>
);

export function dateRangeId(dateRangeLabel) {
  return dateRangeLabel === 'This month'
    ? 'thisMonth'
    : dateRangeLabel === 'This week'
      ? 'thisWeek'
      : dateRangeLabel === 'Last week'
        ? 'lastWeek'
        : dateRangeLabel === 'Last month'
          ? 'lastMonth'
          : 'last30Days';
}
