import React, { useEffect, useRef, useState } from 'react';
import { keyframes, styled } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import { ArrowDropDown, Cached, Check } from '@mui/icons-material';
import convertMillisecondsToSecondsOrMinutes from '../../utils/date.js';
import { DashboardContext } from '../../context/DashboardContext.jsx';
import { useMutation } from '@tanstack/react-query';
import { saveRefreshInterval } from '../../services/DashboardService.js';

// import { useIssues } from '../../hooks/useIssues.jsx';
// import dateUtils from '../../utils/date.js';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '16px',
    transform: 'translate3d: (1000.5px -719px 0px)'
  }
}));

const RefreshButton = ({ isLoading, onRefresh, intervalData }) => {
  const anchorRef = useRef(null);
  const { refreshDuration, setRefreshDuration, refreshEvent, triggerRefresh } =
    React.useContext(DashboardContext);

  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('Refresh');
  const [seconds, setSeconds] = useState(parseInt(refreshDuration) * 60 * 1000);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tableRefreshRate, setTableRefreshRate] = useState(null);

  useEffect(() => {
    if (!intervalData?.error) {
      setRefreshDuration(intervalData.interval);
      setSeconds(parseInt(intervalData.interval) * 60 * 1000);
    }
  }, []);

  const updateIntervalMutation = useMutation({
    mutationFn: ({ updatedInterval }) => {
      try {
        let saveInterval = saveRefreshInterval(updatedInterval);
        return saveInterval;
      } catch (error) {
        return Promise.resolve(null);
      }
    }
  });

  const refreshIcon = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(-180deg);
    }
    `;

  const refreshOptions = [
    {
      value: 0,
      label: 'None'
    },
    {
      value: 1,
      label: '1 minute'
    },
    {
      value: 5,
      label: '5 minutes'
    },
    {
      value: 15,
      label: '15 minutes'
    },
    {
      value: 30,
      label: '30 minutes'
    },
    {
      value: 60,
      label: '1 hour'
    }
  ];

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((second) => second - 1000);
      }, 1000);
      return () => clearInterval(interval);
    } else if (seconds === 0) {
      setSeconds(tableRefreshRate);
      handleRefreshClick();
    }
  }, [seconds, tableRefreshRate, refreshDuration]);

  const addLoading = () => {
    onRefresh();
  };

  const changeTableRefresh = async (event, value, index) => {
    updateIntervalMutation.mutateAsync(
      {
        updatedInterval: parseInt(value)
      },
      {
        onSuccess: (response) => {},
        onSettled: () => {
          handleClose(event);
          setSelectedIndex(index);
          setButtonMessage(
            `${
              value > 0 ? `${value} minute${value > 1 ? 's' : ''}` : 'Refresh'
            }`
          );
          setRefreshDuration(parseInt(value));
          localStorage.setItem('refreshTime', value);
          setSeconds(value * 60 * 1000);
          setTableRefreshRate(value * 60 * 1000);
        }
      }
    );
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseTooltip = (event) => {
    setOpenTooltip(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleRefreshClick = async () => {
    onRefresh();
    setSeconds(parseInt(refreshDuration) * 60 * 1000);
    triggerRefresh();
  };

  const showRefreshTooltip = () => {
    let isTooltipOpen = seconds > 0;
    isTooltipOpen ? setOpenTooltip(true) : handleCloseTooltip();
    return isTooltipOpen;
  };

  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ minWidth: 'fit-content', height: '34px', borderRight: 'none' }}
      >
        <CustomTooltip
          open={openTooltip}
          onOpen={showRefreshTooltip}
          onClose={handleCloseTooltip}
          title={`${convertMillisecondsToSecondsOrMinutes(seconds)}  remaining`}
          followCursor={false}
          placement="top"
          arrow
        >
          <Button
            id="demo-customized-button"
            variant="contained"
            onClick={handleRefreshClick}
            role="refresh-button"
            startIcon={
              <Cached
                sx={
                  isLoading
                    ? {
                        animation: `${refreshIcon} 1.5s infinite}`
                      }
                    : {}
                }
              />
            }
            sx={[
              {
                backgroundColor: 'primary.contrastText',
                color: 'primary.main'
                // pb: '15px'
              },
              {
                '&:hover': {
                  backgroundColor: 'primary.contrastText',
                  color: 'primary.main'
                }
              }
            ]}
          >
            {/*<Box >{buttonMessage} </Box> */}
            {/* <Box sx={{ pl: '15px', fontSize: '22px' }}>|</Box> */}
          </Button>
        </CustomTooltip>

        <Button
          size="medium"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select refresh rate"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={[
            {
              backgroundColor: 'primary.contrastText',
              color: 'primary.main'
            },
            {
              '&:hover': {
                backgroundColor: 'primary.contrastText',
                color: 'primary.main'
              }
            }
          ]}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 5
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper sx={{ width: 150 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {/*<Box sx={{ px: 2, width: "220px", borderBottom: 1, borderColor: "divider" }}>
                                        <Typography variant="h6">Refresh</Typography>
                                    </Box>*/}
                  {refreshOptions.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={option.value == refreshDuration}
                      onClick={(event) =>
                        changeTableRefresh(event, option.value, index)
                      }
                      value={option.value}
                      sx={{ py: '14px' }}
                    >
                      <ListItemText value={option.value}>
                        {option.label}
                      </ListItemText>
                      {option.value == refreshDuration && (
                        <ListItemIcon sx={{ display: 'contents' }}>
                          <Check />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default RefreshButton;
