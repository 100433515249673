import { createContext, useState } from 'react';

let SidebarContext;
let { Provider } = (SidebarContext = createContext());

let SidebarProvider = ({ children }) => {
  let localIsOpen =
    localStorage.getItem('navdrawerstateopen', 'true') === 'true';
  const [isOpen, setIsOpen] = useState(localIsOpen);
  return <Provider value={{ isOpen, setIsOpen }}>{children}</Provider>;
};

export { SidebarContext, SidebarProvider };
